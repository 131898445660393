/// Fonts
$font-family: 'Fira Sans Regular';
$font-family-url: './assets/fonts/fira-sans/FiraSans-Regular.otf';
$font-family-medium: 'Fira Sans Medium';
$font-family-medium-url: './assets/fonts/fira-sans/FiraSans-Medium.otf';
$font-family-bold: 'Fira Sans Bold';
$font-family-bold-url: './assets/fonts/fira-sans/FiraSans-Bold.otf';

/// Colors
$DSM-blue: rgb(0, 52, 120);
$DSM-mid-blue: rgb(0, 112, 186);
$DSM-cyan: rgb(0, 159, 127);
$DSM-burgundy: rgb(151, 13, 52);
$DSM-red: rgb(229, 31, 34);
$DSM-orange: rgb(238, 114, 3);
$DSM-purple: rgb(82, 27, 101);
$DSM-plum: rgb(123, 31, 98);
$DSM-cerise: rgb(189, 10, 121);
$DSM-dark-green: rgb(0, 126, 75);
$DSM-lime-green: rgb(69, 172, 52);
$DSM-ocean-green: rgb(0, 155, 151);

$DSM-dark-grey: rgb(128, 128, 128);
$DSM-grey: rgb(224, 224, 224);
$DSM-light-grey: rgb(240, 240, 240);

$DSM-yellow: rgb(255, 205, 0);
$DSM-bright-green: rgb(208, 223, 0);
$DSM-apple-green: rgb(168, 173, 0);

$DSM-dark-green-mid-blue: rgb(5, 127, 134);


@import '../../assets/scss/functions';


/***************************************** Navigation Bar ***************************************/

.navbar {
    background-image: linear-gradient(to bottom right, $DSM-blue, $DSM-mid-blue);
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, .5);

    .navbar-brand {
        display: flex;
        align-items: center;
        font-family: 'Fira Sans Medium';
        font-size: 1.35rem !important;
        > img {
            height: 45px;
            width: 150px;
            margin: -5px 5px -5px 0;
        }
    }

    .sidebar-toggler {
        margin-right: 20px;
        padding: .25rem .75rem !important;
        font-size: 1.25rem !important;
        line-height: 1 !important;
        &.hide {
            display: none;
        }
    }

    #navbarScrollingDropdownUser.dropdown-toggle{
        padding: 0 0 0 10px;
        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        background-image: linear-gradient(to bottom right, $DSM-blue, $DSM-mid-blue);
        box-shadow: 1px 1px 7px rgba(0, 0, 0, .5);
        opacity: 0.9;
        border-radius: 0 0 10px 10px !important;

        .dropdown-title {
            font-size: 1.1rem;
            text-transform: uppercase;
            padding: .25rem 1rem;
            white-space: nowrap;
            color: #fff;
        }
        .dropdown-item {
            color: #fff !important;
            &:focus, &:hover {
                color: #1e2125 !important;
            }
        }
        .dropdown-divider {
            color: white;
        }
        .parent-divider {
            height: 2px !important;
        }
    }
}

.navbar-dark {
    .btn.btn-outline-light {
        // color: rgba(255, 255, 255, .55);
        border-color: rgba(255, 255, 255, .1);
        > svg {
            fill: #f8f9fa;
        }
        &:hover {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            > svg {
                fill: #000;
            }
        }
    }
}

.react-select-container .react-select__menu {
    background-image: linear-gradient(to bottom right, $DSM-blue, $DSM-mid-blue);
    opacity: 0.9;
    .react-select__menu-list {
        background-color: transparent;
        .react-select__option--is-focused {
            background-color: #f8f9fa;
            color: #1e2125;
        }
    }
}
