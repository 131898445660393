/// Fonts
$font-family: 'Fira Sans Regular';
$font-family-url: './assets/fonts/fira-sans/FiraSans-Regular.otf';
$font-family-medium: 'Fira Sans Medium';
$font-family-medium-url: './assets/fonts/fira-sans/FiraSans-Medium.otf';
$font-family-bold: 'Fira Sans Bold';
$font-family-bold-url: './assets/fonts/fira-sans/FiraSans-Bold.otf';

/// Colors
$DSM-blue: rgb(0, 52, 120);
$DSM-mid-blue: rgb(0, 112, 186);
$DSM-cyan: rgb(0, 159, 127);
$DSM-burgundy: rgb(151, 13, 52);
$DSM-red: rgb(229, 31, 34);
$DSM-orange: rgb(238, 114, 3);
$DSM-purple: rgb(82, 27, 101);
$DSM-plum: rgb(123, 31, 98);
$DSM-cerise: rgb(189, 10, 121);
$DSM-dark-green: rgb(0, 126, 75);
$DSM-lime-green: rgb(69, 172, 52);
$DSM-ocean-green: rgb(0, 155, 151);

$DSM-dark-grey: rgb(128, 128, 128);
$DSM-grey: rgb(224, 224, 224);
$DSM-light-grey: rgb(240, 240, 240);

$DSM-yellow: rgb(255, 205, 0);
$DSM-bright-green: rgb(208, 223, 0);
$DSM-apple-green: rgb(168, 173, 0);

$DSM-dark-green-mid-blue: rgb(5, 127, 134);


@import 'assets/scss/functions';


@import '~bootstrap/scss/bootstrap'; /* import bootstrap to set changes */


/********************************************* Fonts ********************************************/

@font-face {
    font-family: $font-family;
    src: local($font-family), url($font-family-url) format('truetype');
}

@font-face {
    font-family: $font-family-medium;
    src: local($font-family-medium), url($font-family-medium-url) format('truetype');
}

@font-face {
    font-family: $font-family-bold;
    src: local($font-family-bold), url($font-family-bold-url) format('truetype');
}

/******************************************* General *******************************************/

body {
    font-family: $font-family !important;
    // background-image: linear-gradient($DSM-grey -10%, $DSM-blue);
    background-color: rgb(157, 173, 193);
}

#root {
    display: flex;
    width: 100%;
    align-items: stretch;
}

hr {
    opacity: 0.5 !important;
}

.iconify {
    width: 24px;
    height: 24px;
}